import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NotificationService } from '../services/notification.service';
import { NotificationPostModel } from '../models/Notification/notification-post-model';
import { Notification } from '../models/Notification/notification';
import { Subject, interval } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import { FoodLoggerService } from '../services/food-logger.service';
import { FoodLogMasterModel } from '../models/FoodLogger/FoodLog/food-log-master-model';
@Component({
  selector: 'app-jarvis-dashboard',
  templateUrl: './jarvis-dashboard.component.html',
  styleUrls: ['./jarvis-dashboard.component.css']
})
//https://levelup.gitconnected.com/auto-refresh-or-polling-using-rxjs-timer-operator-2141016c7a53
export class JarvisDashboardComponent implements AfterViewInit,OnInit,OnDestroy {
  private unsub = new Subject<void>();
  public lastUpdated='';
  public habitNames='';
  public totaMins=0;
  public totalMinsDone=0;
  public notificationModels: Notification[];
  public currentTime:number;
  public DateFromOutside:string;
  public foodLogMasterModel: FoodLogMasterModel;
  public totalCalories =0;
  public totalCaloriesMeasurementUnit;
  public totalProtien =0;
  public totalProtienMeasurementUnit;
  public totalSodium =0;
  public totalSodiumMeasurementUnit;
  constructor(private elementRef: ElementRef,private notificationService: NotificationService,private datepipe: DatePipe,private foodLoggerservice: FoodLoggerService) {
    var currentDate = new Date();
    this.DateFromOutside = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    setInterval(() => {
      this.currentTime = Date.now();
    }, 1);
  }
  ngOnInit() {

    this.foodLogMasterModel = new FoodLogMasterModel()
    this.foodLoggerservice.GetFoodLogWithNutritionFactByDate(new Date()).subscribe(responseResult=>{
      if (responseResult !== null) {

        var response = responseResult.FoodLoggerFoodLogModels;
        this.foodLogMasterModel = responseResult;
        for (let i = 0; i < this.foodLogMasterModel.FoodLoggerFoodLogModels.length ; i++){

          this.totalCalories += Number(this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Calories);
          this.totalCaloriesMeasurementUnit = this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Calories_Measurement;

          this.totalProtien += Number(this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Protien);
          this.totalProtienMeasurementUnit = this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Protien_Measurement;

          this.totalSodium += Number(this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Sodium);
          this.totalSodiumMeasurementUnit = this.foodLogMasterModel.FoodLoggerFoodLogModels[i].FoodModel.NutritionFactModel.Sodium_Measurement;
        }
      }
    });

    this.notificationModels = [];
    var model = new NotificationPostModel();
    model.ProjectID = 0; model.IsReaded = false;
    this.notificationService.GetAllNotificationOrByApplicationID(model).subscribe(response => {
      this.processResponse(response);
    },
      err => {
        console.log(err.Message);
      }
    );

    interval(3600000)
    .pipe(
      tap((x) => console.log(x)),
      takeUntil(this.unsub),
      switchMap(() => this.notificationService.GetAllNotificationOrByApplicationID(model))
    ).subscribe(response => {
      this.processResponse(response);
    },
      err => {
        console.log(err.Message);
      }
    );

    


    $(document).ready(function () {
      $(".sidebar").css('display', 'none');
      $(".sticky-footer").css('display', 'none');
      $(".breadcrumb").css('display', 'none');
       $(".scroll-to-top-hidemenu").css('display', 'none');
    });
    $("#sidebarToggle").on('click', function (e) {
      e.preventDefault();
      $(".sidebar").css('display', 'inline');
    });
  }
  ngAfterViewInit() {
      this.elementRef.nativeElement.ownerDocument
          .body.style.backgroundColor = 'black';
      $(function(){
        $('.two-circle').css('background', 'blue !important');
        var leng = $('.two-circle').length;;
    });
  }
  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument
    .body.style.backgroundColor = '';
  }
  processResponse(response){
    this.lastUpdated=  "Last Updated - "+ formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.habitNames='';
    this.totaMins=0;
    this.totalMinsDone=0;
    //console.log(response);
    this.notificationModels = response;
    console.log(this.notificationModels);
    var revisionNotification = this.notificationModels.filter(function(e){
      return <any> e.NotificationText.indexOf('R ') >=0;
    });
  //    var newModels = this.notificationModels.filter(function (e) {
  //       return e.no.indexOf('R') >=0;
  // });
  for (let i = 0; i < revisionNotification.length ; i++) {
    var habitName = revisionNotification[i].NotificationText.substring(0, revisionNotification[i].NotificationText.indexOf(" ("));
    var habitTextForMins = revisionNotification[i].NotificationText.substring(0, revisionNotification[i].NotificationText.indexOf("mins"));
    var minsProgressSection = habitTextForMins.slice(-4).trim();
    var minuteDone = minsProgressSection.substring(0,1);
    var totalMins = minsProgressSection.substring(2,3);
    this.totaMins += Number(totalMins);
    this.totalMinsDone += Number(minuteDone);
    this.habitNames += habitName +",";
  }

  }

}